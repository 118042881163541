import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { logExploreCardClicked } from 'cb-wallet-analytics/explore';
import { useGetPriceDetail } from 'cb-wallet-data/hooks/Explore/useGetPriceDetail';
import { ExploreAsset } from 'cb-wallet-data/stores/Explore/models/ExploreAsset';
import { useRouter } from 'next/router';
import { AssetAvatar } from 'wallet-cds-web/components/AssetAvatar';
import { Button } from 'wallet-cds-web/components/Button';
import { ListCell } from '@cbhq/cds-web/cells';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextLabel1, TextLabel2, TextTitle2 } from '@cbhq/cds-web/typography';
import { ProgressBar } from '@cbhq/cds-web/visualizations/ProgressBar';

import { useIsADPSwapSidebarEnabled } from ':dapp/hooks/Swap/useIsADPSwapSidebarEnabled';
import { getDynamicPagePath } from ':dapp/utils/getDynamicPagePath';
import { RoutesEnum } from ':dapp/utils/RoutesEnum';

const messages = defineMessages({
  totalSwapsLabel: {
    defaultMessage: '{total} swaps',
    description: 'Label to show total amount of swaps',
  },
  swapButton: {
    defaultMessage: 'Swap',
    description: 'Button to swap',
  },
  boughtLabel: {
    defaultMessage: '{percentage}% bought',
    description: 'Label to show bought',
  },
  soldLabel: {
    defaultMessage: '{percentage}% sold',
    description: 'Label to show sold',
  },
});

export const EARN_CARD_V2_WIDTH = 408;

type TrendingSwapCardProps = {
  asset: ExploreAsset;
  rowIndex: number;
};

export function TrendingSwapCard({ asset, rowIndex }: TrendingSwapCardProps) {
  const { formatMessage, formatNumber } = useIntl();
  const { formattedAmount, formattedPercent, percentArrow, percentColor } = useGetPriceDetail(
    asset.currencyCode.code,
    asset.uuid,
    true, // no need to fetch / cache price history
  );
  const isSwapEnabled = useIsADPSwapSidebarEnabled({
    assetNetwork: asset.network,
    assetContractAddress: asset.address?.rawValue,
  });
  const router = useRouter();

  /* istanbul ignore next */
  const buys = asset.numOfBuys ?? 0;
  /* istanbul ignore next */
  const sells = asset.numOfSells ?? 0;

  const title = useMemo(() => {
    return (
      <HStack gap={1} alignItems="center">
        <TextLabel1 testID="trending-swap-card-currency-code" as="p">
          {asset.currencyCode.code}
        </TextLabel1>
        <TextLabel2 as="p" color={percentColor}>{`${percentArrow}${formattedPercent}`}</TextLabel2>
      </HStack>
    );
  }, [asset.currencyCode.code, formattedPercent, percentArrow, percentColor]);

  const totalSwaps = useMemo(() => {
    return formatMessage(messages.totalSwapsLabel, { total: formatNumber(buys + sells) });
  }, [buys, formatMessage, formatNumber, sells]);

  const getPercentage = useCallback(
    (amount: number) => {
      return (amount / (buys + sells)) * 100;
    },
    [buys, sells],
  );

  /* istanbul ignore next */
  const handleSwapPress = useCallback(() => {
    logExploreCardClicked({
      componentName: 'trending_swap_card',
      currencyCode: asset.currencyCode.code,
      rowIndex,
    });

    router.push(
      getDynamicPagePath(RoutesEnum.ASSETS, {
        assetBlockchain: asset.blockchain,
        assetNetwork: asset.network,
        assetCurrencyCode: asset.currencyCode,
        assetName: asset.name,
        assetContractAddress: asset.address?.rawValue,
        assetUUID: asset.uuid,
      }),
    );
  }, [asset, router, rowIndex]);

  return (
    <Box
      spacingEnd={1}
      width={EARN_CARD_V2_WIDTH}
      maxWidth={EARN_CARD_V2_WIDTH}
      testID={`trending-swap-card-${asset.currencyCode.code}`}
    >
      <VStack
        background="backgroundAlternate"
        borderRadius="roundedLarge"
        spacingBottom={2}
        width="100%"
      >
        <ListCell
          media={
            <AssetAvatar
              assetSrc={asset.imageURL}
              assetCurrencyCode={asset.currencyCode.code}
              assetSize="xl"
            />
          }
          title={title}
          description={
            <TextLabel2 as="p" color="foregroundMuted">
              {formattedAmount}
            </TextLabel2>
          }
        />

        <VStack gap={2} spacingHorizontal={3}>
          <TextTitle2 numberOfLines={1} as="p">
            {totalSwaps}
          </TextTitle2>

          <HStack width="100%" flexGrow={1}>
            <Box width={`${getPercentage(buys)}%`}>
              <ProgressBar color="positive" progress={1} weight="normal" />
            </Box>
            <Box width={`${getPercentage(sells)}%`}>
              <ProgressBar color="negative" progress={1} weight="normal" />
            </Box>
          </HStack>

          <HStack justifyContent="space-between">
            <HStack gap={1} alignItems="center">
              <Icon name="dot" size="xs" color="positive" />
              <TextBody color="foregroundMuted" as="p">
                {formatMessage(messages.boughtLabel, {
                  percentage: Math.round(getPercentage(buys)),
                })}
              </TextBody>
            </HStack>

            <HStack gap={1} alignItems="center">
              <Icon name="dot" size="xs" color="negative" />
              <TextBody color="foregroundMuted" as="p">
                {formatMessage(messages.soldLabel, {
                  percentage: Math.round(getPercentage(sells)),
                })}
              </TextBody>
            </HStack>
          </HStack>

          <Button
            variant="secondary"
            block
            compact
            onPress={handleSwapPress}
            disabled={!isSwapEnabled}
            testID={`trending-swap-button-${asset.currencyCode.code}`}
          >
            {formatMessage(messages.swapButton)}
          </Button>
        </VStack>
      </VStack>
    </Box>
  );
}
