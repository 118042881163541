import { useEffect, useMemo } from 'react';
import {
  SupportedTrendingSwapsNetwork,
  useTrendingSwaps,
} from 'cb-wallet-data/stores/Explore/api/useTrendingSwaps';
import { ExploreAsset } from 'cb-wallet-data/stores/Explore/models/ExploreAsset';

import { useSetExploreAssetsMap } from './useSetExploreAssetsMap';

export function useGetTrendingSwaps(networkName: SupportedTrendingSwapsNetwork) {
  const { data, isLoading } = useTrendingSwaps(networkName);
  const setExploreAssetsMap = useSetExploreAssetsMap();

  const trendingSwaps = useMemo(() => (data ?? []).map((asset) => new ExploreAsset(asset)), [data]);

  useEffect(() => {
    setExploreAssetsMap(trendingSwaps);
  }, [trendingSwaps, setExploreAssetsMap]);

  return {
    isLoading,
    data: trendingSwaps,
  };
}
