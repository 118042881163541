import { defineMessages, useIntl } from 'react-intl';
import { PlayerRank } from 'cb-wallet-data/hooks/Gamification/useGetUserRank';
import { usePublicProfileByAddresses } from 'cb-wallet-data/stores/DecentralizedID/hooks/usePublicProfileByAddresses';
import { ProfileAvatar } from 'wallet-cds-web/components/ProfileAvatar';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import { useIsMobile } from ':dapp/hooks/useIsMobile';
import { useProfileName } from ':dapp/hooks/useProfileName';

type LeaderboardItemProp = {
  rank: number;
  user: PlayerRank;
  isHighlighted?: boolean;
};

const messages = defineMessages({
  points: {
    defaultMessage: '{value} points',
    description: 'points section of leaderboard item',
  },
});

export function LeaderboardItem({ rank, user, isHighlighted = false }: LeaderboardItemProp) {
  const isMobile = useIsMobile();
  const { formatMessage, formatNumber } = useIntl();
  const { profiles } = usePublicProfileByAddresses({
    addresses: [user.userAddress],
  });
  const name = useProfileName({ profiles });

  return (
    <Box
      background={isHighlighted ? 'secondary' : 'backgroundAlternate'}
      borderRadius="roundedMedium"
      spacing={2}
    >
      {isMobile ? (
        <HStack width="100%">
          <TextHeadline as="span">{rank}</TextHeadline>
          <VStack spacingHorizontal={2}>
            <TextBody as="span">{name}</TextBody>
            <TextBody as="span">
              {formatMessage(messages.points, {
                value: formatNumber(user.currentScore),
              })}
            </TextBody>
          </VStack>
        </HStack>
      ) : (
        <HStack justifyContent="space-between" width="100%" alignItems="center">
          <HStack gap={2} alignItems="center">
            <TextHeadline as="span">{rank}</TextHeadline>
            <ProfileAvatar address={user.userAddress} size="xl" />
            <TextBody as="span">{name}</TextBody>
          </HStack>
          <TextBody as="span">
            {formatMessage(messages.points, {
              value: formatNumber(user.currentScore),
            })}
          </TextBody>
        </HStack>
      )}
    </Box>
  );
}
