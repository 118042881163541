/* istanbul ignore file */
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { getJSON } from 'cb-wallet-http/fetchJSON';

import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';

import { GetFeaturedContentUnauthResponse } from '../types/homeTypes';

export function useHomeFeatured(handleError?: (err?: Error) => void) {
  const { account, accountsWithBalances } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS,
  });

  const result = useQuery({
    queryKey: ['getContentByFeaturedUnauth', account],
    queryFn: async () => {
      return getJSON<GetFeaturedContentUnauthResponse>('explore/getContentByFeaturedUnauth', {
        surface: '1',
        walletAddress: account?.primaryAddressForDapp ?? '',
      });
    },

    enabled: Boolean(
      accountsWithBalances.length === 0 || (accountsWithBalances.length > 0 && !!account),
    ),
    onError: function handleOnError(err?: Error) {
      if (handleError) {
        handleError(err);
      }
    },
    staleTime: 1000 * 30,
    suspense: false,
  });

  if (accountsWithBalances.length > 0 && !account) {
    return {
      data: null,
      isLoading: true,
    };
  }

  return result;
}
