import { assetsMapAtom } from 'cb-wallet-data/stores/Assets/state';
import { useRecoilValue, useSetRecoilState } from 'recoil';

export function useAssetsMap() {
  return useRecoilValue(assetsMapAtom);
}

export function useSetAssetsMap() {
  return useSetRecoilState(assetsMapAtom);
}
