import { useMemo } from 'react';
import { useIsTradeEligible } from 'cb-wallet-data/hooks/Swap/useIsTradeEligible/useIsTradeEligible';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { useSupportedSwapChains } from 'cb-wallet-data/stores/Swap/api/useSupportedSwapChains';
import { useSwapAsset } from 'cb-wallet-data/stores/Swap/api/useSwapAsset';
import { useSwapByChainKillSwitch } from 'cb-wallet-data/stores/Swap/api/useSwapByChainKillSwitch';

type UseIsSwapSidebarEnabledProps = {
  assetNetwork: Network;
  assetContractAddress?: string;
};

export function useIsADPSwapSidebarEnabled({
  assetNetwork,
  assetContractAddress,
}: UseIsSwapSidebarEnabledProps) {
  const isSwapEligible = useIsTradeEligible();
  const supportedChains = useSupportedSwapChains();
  const isSwapByChainKilled = useSwapByChainKillSwitch();

  const swapAsset = useSwapAsset({
    chainId: assetNetwork.asChain()?.chainId.toString(),
    contractAddress: assetContractAddress,
  });

  return useMemo(() => {
    // If swap is kill-switched, return false
    if (!isSwapEligible) {
      return false;
    }

    // If we don't have a valid chainId, return false
    const currentAssetChainId = assetNetwork.asChain()?.chainId;
    if (!currentAssetChainId) {
      return false;
    }

    // If the current chain is not supported, return false
    const isSwapChainSupported = supportedChains.some((supportedChain) => {
      return supportedChain.chainId === BigInt(currentAssetChainId);
    });
    if (!isSwapChainSupported) {
      return false;
    }

    // If the current chain is kill-switched, return false
    const isSwapChainKilled = isSwapByChainKilled(BigInt(currentAssetChainId));
    if (isSwapChainKilled) {
      return false;
    }

    // If the asset is not supported by the swap feature, return false
    if (!swapAsset) {
      return false;
    }

    // Let's swap!
    return true;
  }, [assetNetwork, isSwapByChainKilled, isSwapEligible, supportedChains, swapAsset]);
}
