import { defineMessages, useIntl } from 'react-intl';
import Link from 'next/link';
import { Grid } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography';

import { CardWithImageLoading } from ':dapp/components/Card/CardWithImage';
import { ExperienceCard } from ':dapp/pages/home/components/ExperienceCard';
import { HomeSectionHeader } from ':dapp/pages/home/components/HomeSectionHeader';
import { ContentComponent } from ':dapp/pages/home/types/homeTypes';
import { RoutesEnum } from ':dapp/utils/RoutesEnum';

const messages = defineMessages({
  homeTrendingSectionHeaderTitle: {
    defaultMessage: 'Trending onchain',
    description: "section header title for Onchain Summer's Trending onchain section",
  },
  homeTrendingSectionHeaderSubtitle: {
    defaultMessage: 'The onchain experiences everyone’s doing today ',
    description: "section header subtitle for Onchain Summer's Trending onchain section",
  },
  homeTrendingSectionLink: {
    defaultMessage: 'See experiences',
    description: 'link for trending section for onchain summer',
  },
});

type TrendingSectionProps = {
  cards: ContentComponent[];
  isLoading: boolean;
};

export function HomeTrendingSection(props: TrendingSectionProps) {
  const { formatMessage } = useIntl();

  if (props.cards.length === 0) {
    return null;
  }

  return (
    <>
      <HomeSectionHeader
        title={formatMessage(messages.homeTrendingSectionHeaderTitle)}
        subtitle={formatMessage(messages.homeTrendingSectionHeaderSubtitle)}
        spacingBottom={4}
        rightContent={
          <Link href={RoutesEnum.HOME_EXPLORE}>
            <TextBody as="span" color="primary" noWrap>
              {formatMessage(messages.homeTrendingSectionLink)}
            </TextBody>
          </Link>
        }
      />
      <Grid templateColumns="repeat(auto-fit, minmax(327px, 1fr))" gap={4} spacingBottom={4}>
        {props.isLoading
          ? Array(6)
              .fill(0)
              // eslint-disable-next-line react/no-array-index-key
              .map((_, index) => <CardWithImageLoading key={`loading-${index}`} />)
          : props.cards.map((card) => {
              return <ExperienceCard card={card} key={`trending-${card.id}`} />;
            })}
      </Grid>
    </>
  );
}
