import { Pressable } from '@cbhq/cds-web/system';
import { TextCaption } from '@cbhq/cds-web/typography';
type GradientBadgeProps = {
  title: string;
};
export function GradientBadge({
  title
}: GradientBadgeProps) {
  const GRADIENT_CLASS = "g1j4d186";
  return <Pressable noScaleOnPress background="background" borderRadius="roundedFull" className={GRADIENT_CLASS}>
      <TextCaption as="span">{title}</TextCaption>
    </Pressable>;
}

require("./GradientBadge.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./GradientBadge.tsx");