import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useGetUserRank } from 'cb-wallet-data/hooks/Gamification/useGetUserRank';
import { useGetUserStateV2 } from 'cb-wallet-data/hooks/Gamification/useUserOCSProfile';
import { usePublicProfileByAddresses } from 'cb-wallet-data/stores/DecentralizedID/hooks/usePublicProfileByAddresses';
import { ProfileAvatar } from 'wallet-cds-web/components/ProfileAvatar';
import { Box, Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextCaption, TextHeadline, TextTitle3 } from '@cbhq/cds-web/typography';
import { useAutoClaimChallenges } from ':dapp/hooks/useAutoClaimChallenges';
import { useIsMobile } from ':dapp/hooks/useIsMobile';
import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';
import { useProfileName } from ':dapp/hooks/useProfileName';
const THEME_BORDER = "t2kfsog";
const STAT_BOX = "s4bngsw";
const messages = defineMessages({
  score: {
    defaultMessage: 'POINTS',
    description: 'points section of banner'
  },
  rank: {
    defaultMessage: 'MY RANK',
    description: 'rank section of banner'
  }
});
export function HomeScoreBanner() {
  const {
    account
  } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS
  });
  const {
    profiles
  } = usePublicProfileByAddresses({
    addresses: account ? [account?.primaryAddress] : []
  });
  const isMobile = useIsMobile();
  const profileName = useProfileName({
    profiles
  });
  const {
    userState
  } = useGetUserStateV2(account?.primaryAddress);
  const {
    userRank
  } = useGetUserRank(account?.primaryAddress);
  useAutoClaimChallenges();
  return useMemo(() => {
    if (!account?.primaryAddress) {
      return null;
    }
    return <VStack borderRadius="roundedXLarge" className={THEME_BORDER} background="primary" overflow="hidden" testID="home-score-banner">
        {!isMobile ? <HStack spacing={2} justifyContent="space-between">
            <HStack alignItems="center" gap={1}>
              <ProfileAvatar address={account?.primaryAddress} size="xxl" />
              <TextTitle3 as="p">{profileName}</TextTitle3>
              {userState?.playedOCS && <img src="/home/badges/ocs_badge.svg" alt="OCS Badge" />}
            </HStack>
            <Box width={400}>
              {userState && <ScoreBannerStats currentScore={userState.currentScore} rank={userRank?.rank} />}
            </Box>
          </HStack> : <VStack gap={2} spacing={2}>
            <HStack alignItems="center" gap={1}>
              <ProfileAvatar address={account?.primaryAddress} size="xxl" />
              <TextTitle3 as="p">{profileName}</TextTitle3>
              {userState?.playedOCS && <img src="/home/badges/ocs_badge.svg" alt="OCS Badge" />}
            </HStack>
            {userState && <ScoreBannerStats currentScore={userState.currentScore} rank={userRank?.rank} />}
          </VStack>}
      </VStack>;
  }, [userState, isMobile, account?.primaryAddress, profileName, userRank]);
}
type ScoreBannerStatsProps = {
  currentScore: bigint;
  rank: string | undefined;
};
function ScoreBannerStats({
  currentScore,
  rank
}: ScoreBannerStatsProps) {
  const {
    formatMessage,
    formatNumber
  } = useIntl();
  const isMobile = useIsMobile();
  return <Grid as="span" columns={isMobile ? 1 : 2} gap={2} width="100%">
      <Box borderRadius="roundedLarge" className={STAT_BOX} spacing={2}>
        <HStack gap={2} testID="hstack" alignItems="center">
          <img src="/home/badges/points.svg" alt="Total Points Icon" />
          <VStack>
            <TextHeadline as="p">{formatNumber(currentScore)}</TextHeadline>
            <TextCaption as="p" color="foregroundMuted">
              {formatMessage(messages.score)}
            </TextCaption>
          </VStack>
        </HStack>
      </Box>
      <Box borderRadius="roundedLarge" className={STAT_BOX} spacing={2}>
        <HStack gap={2} testID="hstack" alignItems="center">
          <img src="/home/badges/rank.svg" alt="My Rank Icon" />
          <VStack>
            <TextHeadline as="p">#{rank || ' ---'}</TextHeadline>
            <TextCaption as="p" color="foregroundMuted">
              {formatMessage(messages.rank)}
            </TextCaption>
          </VStack>
        </HStack>
      </Box>
    </Grid>;
}

require("./HomeScoreBanner.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./HomeScoreBanner.tsx");