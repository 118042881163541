/**
 * Represents an exchangeable asset (ERC20 or ETH)
 *
 * @property name Asset display name
 * @property currencyCode Asset symbol i.e. ETH, BAT, USDC
 * @property contractAddress ERC20 contract address or null for Ethereum
 * @property decimals Number of decimals to represent asset's value in base unit
 * @property imageURL Assets logo URL
 * @property swappable Whether the asset is supported for native swap
 * @property aggregatorIDs The list of supported aggregators
 */
import { ETHEREUM_PREFIX } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { SOLANA_PREFIX } from 'cb-wallet-data/chains/AccountBased/Solana/utils/chain';
import { Asset } from 'cb-wallet-data/stores/Assets/Asset';

import { SwapAssetResponse } from '../types/SwapAssetResponse';

export class SwapAsset extends Asset {
  swappable: boolean;
  aggregators: string[];
  isBuyableWithCBPay?: boolean;
  swapId: string;
  unverified: boolean;

  constructor(swapAssetResponse: SwapAssetResponse) {
    super(
      swapAssetResponse.name,
      swapAssetResponse.currencyCode,
      swapAssetResponse.address,
      swapAssetResponse.decimals,
      swapAssetResponse.imageURL,
      swapAssetResponse.chainId,
      swapAssetResponse.blockchain,
    );
    this.swappable = swapAssetResponse.swappable;
    this.aggregators = swapAssetResponse.aggregators;
    this.isBuyableWithCBPay = swapAssetResponse.isBuyableWithCBPay || false;
    // Setting undefined to true for backwards compatibility
    this.unverified =
      swapAssetResponse.unverified === undefined ? false : swapAssetResponse.unverified;

    let prefix = ETHEREUM_PREFIX;
    if (Number(this.chainId) === 101) prefix = SOLANA_PREFIX;

    this.swapId = `${this.blockchain.rawValue.toUpperCase()}/${this.currencyCode.rawValue.toUpperCase()}/${prefix}:${
      this.chainId
    }/false/${this.address?.rawValue ?? 'undefined'}`;
  }
}
