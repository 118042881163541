import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useFormatPercent } from 'cb-wallet-data/hooks/useFormatPercent';
import { useAsset } from 'cb-wallet-data/stores/Assets/hooks/useAsset';
import { AssetPriceResponse, RESOLUTION_DAY } from 'cb-wallet-data/stores/Prices/api';
import { usePrices } from 'cb-wallet-data/stores/Prices/hooks/usePrices';
import { PaletteForeground } from '@cbhq/cds-common';
import { usePalette } from '@cbhq/cds-mobile/hooks/usePalette';

export const ARROW_UP_DIAGONAL = '\u2197';
export const ARROW_DOWN_DIAGONAL = '\u2198';

/**
 *
 * Fetches the current day's price detail.  Optionally fetches the day's price history as well.
 *
 * @param currencyCode the asset currency code
 * @param uuid the coinbase defined uuid
 * @param omitPriceHistory whether to omit the price history data from response (also avoids caching it unnecessarily)
 * @returns
 */
export function useGetPriceDetail(
  currencyCode: string,
  uuid: string | undefined,
  omitPriceHistory: boolean,
): {
  formattedPercent: string;
  formattedAmount: string;
  color: string;
  prices?: AssetPriceResponse;
  percentColor: PaletteForeground;
  percentArrow: string;
} {
  const { formatNumber } = useIntl();
  const formatPercent = useFormatPercent();
  const colors = usePalette();
  // TODO: Asset Metadata V4, use enforced parameters
  const { asset } = useAsset({ assetSymbol: currencyCode, uuid });
  const { data: prices } = usePrices({
    assetId: asset?.id,
    onlyPercentChangeResolutions: omitPriceHistory ? [RESOLUTION_DAY] : undefined,
  });
  const latestPrice = prices?.prices.latestPrice;
  const price = latestPrice?.amount;
  const percentChange = latestPrice?.percentChange.day;
  const formattedAmount: string = price
    ? formatNumber(Math.abs(Number(price?.amount)), {
        style: 'currency',
        currency: price?.currency,
        minimumFractionDigits: price?.scale,
      })
    : '';
  const isIncrease = percentChange ? percentChange > 0 : false;
  const isZero = percentChange !== undefined ? percentChange === 0 : false;
  const formattedPercent =
    percentChange !== undefined ? formatPercent(Math.abs(percentChange), 2) : '';

  const percentArrow = useMemo(() => {
    if (isZero || !percentChange) return '';
    return isIncrease ? ARROW_UP_DIAGONAL : ARROW_DOWN_DIAGONAL;
  }, [isZero, percentChange, isIncrease]);

  const percentColor = useMemo(() => {
    if (isZero || !percentChange) return 'foregroundMuted';
    return isIncrease ? 'positive' : 'negative';
  }, [isZero, percentChange, isIncrease]);

  return {
    formattedPercent,
    formattedAmount,
    color: asset?.color ?? colors.primary,
    prices: omitPriceHistory ? undefined : prices,
    percentArrow,
    percentColor,
  };
}
