import { Box, HStack, VStack } from '@cbhq/cds-web/layout';

export const testIDLoading = 'home-header-loading';

export function HomeHeaderLoading() {
  return (
    <Box width="100%" height={440} spacingHorizontal={4} testID={testIDLoading}>
      <HStack width="100%" borderRadius="roundedXLarge" overflow="hidden">
        <Box width="50%" background="backgroundAlternate" spacing={4}>
          <VStack width="100%" justifyContent="space-between">
            <VStack width="100%" gap={3}>
              <Box width={128} height={24} background="secondary" borderRadius="roundedXLarge" />
              <VStack width="100%" gap={1.5}>
                <HStack gap={1} alignItems="center">
                  <Box width={40} height={40} background="secondary" borderRadius="rounded" />
                  <Box width={80} height={28} background="secondary" />
                </HStack>
                <Box width="75%" height={56} background="secondary" />
                <Box width="100%" height={28} background="secondary" />
                <Box width="100%" height={28} background="secondary" />
              </VStack>
            </VStack>
            <Box width="100%" height={56} borderRadius="roundedXLarge" background="secondary" />
          </VStack>
        </Box>
        <Box width="50%" background="secondary" />
      </HStack>
    </Box>
  );
}
