import { Asset } from 'cb-wallet-data/stores/Assets/Asset';
import { ExploreAsset } from 'cb-wallet-data/stores/Explore/models/ExploreAsset';
import { atom } from 'recoil';

export type GenericAsset = Asset | ExploreAsset;
const DEFAULT_ASSETS = new Map<string, GenericAsset>();
const STATE_KEY = 'assetsMap';

export const assetsMapAtom = atom<Map<string, GenericAsset>>({
  key: STATE_KEY,
  default: DEFAULT_ASSETS,
});
