import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { useActiveFiatCurrency } from 'cb-wallet-data/stores/ActiveFiatCurrency/hooks/useActiveFiatCurrency';

import { AssetPriceResponse, getAssetPricesV3, PricesForPeriod, RESOLUTIONS } from '../api';

const QUERY_KEY = 'coinbase_asset_prices';

const staleTime = 1000 * 30; // Background refresh when requested again after 30 seconds

type Props = {
  assetId?: string;
  // If provided, will only include percent change for these resolutions (price history will be omitted)
  // Only applicable to v3 api
  onlyPercentChangeResolutions?: string[];
};

export function usePricesV3({ assetId, onlyPercentChangeResolutions }: Props) {
  const { code } = useActiveFiatCurrency();
  const request = { uuid: assetId, currency: code.rawValue, resolutions: RESOLUTIONS };

  if (onlyPercentChangeResolutions) {
    request.resolutions = onlyPercentChangeResolutions; // we still need to get the day resolution so we can get the percent change
  }

  const queryPayload = Buffer.from(JSON.stringify(request), 'utf-8').toString('base64');
  const { data, isFetched } = useQuery(
    [QUERY_KEY, queryPayload],
    async function getAssetPricesV3AndOptionallyOmitPriceHistory() {
      const assetPrices = await getAssetPricesV3(queryPayload, assetId, code.rawValue);
      if (onlyPercentChangeResolutions && assetPrices) {
        return omitPriceHistoryFromResponse(assetPrices);
      }
      return assetPrices;
    },
    {
      enabled: Boolean(assetId),
      staleTime,
      notifyOnChangeProps: ['data'],
    },
  );

  return { data, isFetched };
}

function omitPriceHistoryFromResponse(assetPrices: AssetPriceResponse): AssetPriceResponse {
  const emptyPrices: PricesForPeriod = {
    prices: [],
    yaxisScalingFactor: 1, // Should never be used, but safest to return 1
  };

  return {
    baseId: assetPrices.baseId,
    base: assetPrices.base,
    currency: assetPrices.currency,
    prices: {
      latest: assetPrices.prices.latest,
      latestPrice: assetPrices.prices.latestPrice,
      hour: emptyPrices,
      day: emptyPrices,
      month: emptyPrices,
      week: emptyPrices,
      year: emptyPrices,
      all: emptyPrices,
    },
    unitPriceScale: assetPrices.unitPriceScale,
  };
}

export function usePrices({ assetId, onlyPercentChangeResolutions }: Props) {
  const { data: pricesV3Data, isFetched: isPricesV3DataFetched } = usePricesV3({
    assetId,
    onlyPercentChangeResolutions,
  });

  return { data: pricesV3Data, isFetched: isPricesV3DataFetched };
}

export function useInvalidatePricesQueries() {
  const queryClient = useQueryClient();
  return useCallback(
    function invalidatePricesQueries() {
      queryClient.invalidateQueries([QUERY_KEY]);
    },
    [queryClient],
  );
}
