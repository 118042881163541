import { useMemo, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { logExploreCardViewed } from 'cb-wallet-analytics/explore';
import { useOnMount } from 'cb-wallet-data/hooks/useOnMount';
import { SupportedTrendingSwapsNetwork } from 'cb-wallet-data/stores/Explore/api/useTrendingSwaps';
import { useGetTrendingSwaps } from 'cb-wallet-data/stores/Explore/hooks/useGetTrendingSwaps';
import { VStack } from '@cbhq/cds-web/layout';

import { CarouselArrows } from ':dapp/components/ResponsiveCarousel/CarouselArrows';
import { CarouselContainer } from ':dapp/components/ResponsiveCarousel/CarouselContainer';
import { CarouselProvider } from ':dapp/components/ResponsiveCarousel/CarouselProvider';

import { HomeSectionHeader } from './HomeSectionHeader';
import { TrendingSwapCard } from './TrendingSwapCard';

type TrendingSwapsProps = {
  networkName: SupportedTrendingSwapsNetwork;
  rowIndex: number;
};

const messages = defineMessages({
  trendingSwaps: {
    defaultMessage: 'Trending swaps',
    description: 'section header title for the trending swaps section',
  },
  description: {
    defaultMessage: 'Tokens with the most swaps in the past 24 hours on Coinbase Wallet',
    description: 'section header subtitle for the trending swaps section',
  },
});

export function TrendingSwaps({ networkName, rowIndex }: TrendingSwapsProps) {
  const { data, isLoading } = useGetTrendingSwaps(networkName);
  const { formatMessage } = useIntl();
  const watchedHeightRef = useRef<HTMLDivElement>(null);

  useOnMount(() => {
    logExploreCardViewed({ componentName: 'trending_swaps', rowIndex });
  });

  return useMemo(() => {
    if (isLoading || data.length === 0) {
      return null;
    }

    return (
      <CarouselProvider>
        <VStack gap={2} spacingBottom={4} testID="trending-swaps">
          <HomeSectionHeader
            title={formatMessage(messages.trendingSwaps)}
            subtitle={formatMessage(messages.description)}
            spacingBottom={1}
            rightContent={<CarouselArrows />}
            overrideMobile
          />
          <CarouselContainer watchedHeightRef={watchedHeightRef}>
            {data.map((item, index) => (
              <TrendingSwapCard
                asset={item}
                key={item.address?.rawValue || index}
                rowIndex={rowIndex}
              />
            ))}
          </CarouselContainer>
        </VStack>
      </CarouselProvider>
    );
  }, [data, formatMessage, isLoading, rowIndex]);
}
