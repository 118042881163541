import { useMemo } from 'react';
import { usePageOutageContext } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';
import { Spacer, VStack } from '@cbhq/cds-web/layout';

import { withFeatureEnabled } from ':dapp/components/withFeatureEnabled/withFeatureEnabled';

import { HomeHeader } from '../components/HomeHeader';
import { HomeOutage } from '../components/HomeOutage';
import { HomePageWrapper } from '../components/HomePageWrapper';
import { HomeScoreBanner } from '../components/HomeScoreBanner';
import { LeaderboardSection } from '../components/Leaderboard/LeaderboardSection';
import { TrendingSwaps } from '../components/TrendingSwaps';
import { useHomeTrending } from '../hooks/useHomeTrending';

import { HomeTrendingSection } from './components/TrendingSection';

function HomeTrendingPage() {
  const { setExploreApiIssue, exploreApiIssue } = usePageOutageContext();
  const { data, isLoading } = useHomeTrending(setExploreApiIssue);
  const trendingCards = useMemo(() => data?.contentData || [], [data]);

  return (
    <VStack width="100%" testID="home-tab-trending">
      <HomePageWrapper>
        <HomeHeader />

        {exploreApiIssue ? (
          <HomeOutage />
        ) : (
          <>
            <Spacer vertical={4} />
            <HomeScoreBanner />
            <Spacer vertical={4} />
            <HomeTrendingSection cards={trendingCards} isLoading={isLoading} />
            <TrendingSwaps networkName="base" rowIndex={1} />
            <LeaderboardSection />
          </>
        )}
      </HomePageWrapper>
    </VStack>
  );
}
export default withFeatureEnabled(HomeTrendingPage, 'explore_pages');
