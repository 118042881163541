import { useMemo } from 'react';
import { InfiniteData } from '@tanstack/react-query';

import {
  GetContentUnauthResponse,
  GetFeaturedContentUnauthResponse,
  OCSChallengeCard,
} from '../types/homeTypes';

export function useFirstInCompletedChallenge(
  data: GetFeaturedContentUnauthResponse | null | undefined,
  experiences: InfiniteData<GetContentUnauthResponse> | undefined,
): OCSChallengeCard | null {
  return useMemo(() => {
    if (data && !experiences) {
      return { ...data.content.ocsChallengeCard, id: data.content.id };
    }

    if (!data && experiences) {
      const incompleteChallenge = experiences.pages
        .flatMap((page) => page.contentData)
        .find((content) => content.ocsChallengeCard.hasCompleted === 1);

      return incompleteChallenge
        ? { ...incompleteChallenge.ocsChallengeCard, id: incompleteChallenge.id }
        : null;
    }

    if (experiences && data) {
      const incompleteChallenge = experiences.pages
        .flatMap((page) => page.contentData)
        .find((content) => content.ocsChallengeCard.hasCompleted === 1);

      const currentChallenge = { ...data.content.ocsChallengeCard, id: data.content.id };

      if (!incompleteChallenge) {
        return currentChallenge;
      }

      return data.content.ocsChallengeCard.hasCompleted <= 1
        ? currentChallenge
        : { ...incompleteChallenge.ocsChallengeCard, id: incompleteChallenge.id };
    }

    return null;
  }, [data, experiences]);
}
