import { ActionType, ComponentType } from '@cbhq/client-analytics';

import { logDataEvent, logEvent, useLogEventOnMount } from '../utils/log';

// eslint-disable-next-line @cbhq/ts-no-enum
export enum ExploreContext {
  TOP_MOVERS = 'top_movers',
  TRENDING_COINS = 'trending_coins',
  GAMING_COINS = 'gaming_coins',
  TOP_COINS = 'top_coins',
  TRENDING_COLLECTIONS = 'trending_collections',
  UPCOMING_DROPS = 'upcoming_drops',
  WATCHLIST = 'watchlist',
  RECOMMENDED_COINS = 'recommended_coins',
  EARN = 'earn',
  MINT_STREAKS = 'mint_streaks',
  QUESTS = 'quests',
  WEB_3_STARTER_KIT = 'web_3_starter_kit',
  PARTNER_UPCOMING_DROPS = 'promotional_drops_upcoming', // to be deprecated once partner drops are used for all NFT drop modules
  PARTNER_DROPS = 'partner_drops',
  OCS_MINTING = 'promotional_drops_active',
  SPOTLIGHT = 'spotlight',
  CTA_LIST = 'cta_list',
  GAS_TRACKER = 'gas_tracker',
  NFT_PROMOTION = 'nft_promotion',
  TRENDING_SWAPS = 'trending_swaps',
  TRENDING_SWAPS_SOLANA = 'trending_swaps_solana',
}

type AdhocParams = {
  context?: ExploreContext | string;
  chainName?: string;
  asset?: string;
  rank?: number;
  collection?: string;
  componentName?: string;
  uuid?: string;
  toggleValue?: boolean;
  currencyCode?: string;
  rowIndex?: number;
  message?: string;
};

export function useLogExploreNuxViewed() {
  useLogEventOnMount('explore.explore_nux.viewed', {
    componentType: ComponentType.page,
    action: ActionType.view,
  });
}

export function logExploreNuxButtonClicked() {
  logEvent('explore.explore_nux_button.clicked', {
    componentType: ComponentType.button,
    action: ActionType.click,
  });
}

export function logExplorePageViewed() {
  logEvent('explore.explore_screen.viewed', {
    loggingId: '585ba312-736a-497e-917c-f6a86fbb8809',
    componentType: ComponentType.page,
    action: ActionType.view,
  });
}

export function logExploreEmptyStateViewed() {
  logEvent('explore.empty_state.viewed', {
    componentType: ComponentType.page,
    action: ActionType.view,
  });
}

export function logExploreSectionError({ context }: AdhocParams) {
  logEvent('explore.explore_section.error', {
    loggingId: '681f12b8-f829-4b70-a1a5-abb6b284ef4e',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    context,
  });
}

export function logExploreAssetClicked({ context, chainName, asset, rank, rowIndex }: AdhocParams) {
  logEvent('asset_card.clicked', {
    loggingId: '83a4d135-afab-4bd4-945f-bcbe9768a07a',
    componentType: ComponentType.card,
    action: ActionType.click,
    context,
    chainName,
    asset,
    rankIndex: rank,
    rowIndex,
  });
}

export function logExploreCardViewed({ componentName, rowIndex }: AdhocParams) {
  logEvent(`explore.generic_explore_card.viewed`, {
    loggingId: '092a6662-9126-47ce-b9e2-840c73cff643',
    componentType: ComponentType.card,
    componentName,
    rowIndex,
    action: ActionType.view,
  });
}

export function logExploreCardClicked({ componentName, currencyCode, rowIndex }: AdhocParams) {
  logEvent(`explore.generic_explore_card.clicked`, {
    loggingId: 'bce89016-8ce5-40f0-85a2-a777e9054268',
    componentName,
    componentType: ComponentType.card,
    action: ActionType.click,
    currencyCode,
    rowIndex,
  });
}

/**
 * this event does not get sent to amplitude as it is filtered out by the data team at the /amp endpoint. the event
 * is only sent snowflake into the `event.analytics_service` table
 */
function logExploreAssetViewed({ context, chainName, asset, rank, rowIndex }: AdhocParams) {
  logEvent(
    'asset_card',
    {
      loggingId: '27f3a471-379f-4704-994f-ca9f489e8203',
      componentType: ComponentType.card,
      action: ActionType.view,
      context,
      chainName,
      asset,
      rankIndex: rank,
      rowIndex,
    },
    undefined,
    true,
  );
}

function logExploreMissingAssetImage({ context, chainName, asset, rank }: AdhocParams) {
  logDataEvent('asset_card.asset_missing_image', {
    loggingId: '1fc308c9-f498-495a-a6c2-d3c9311de04d',
    componentType: ComponentType.card,
    action: ActionType.view,
    context,
    chainName,
    asset,
    rankIndex: rank,
  });
}

export function logExploreAssetMissingInfo({ chainName, asset, uuid, message }: AdhocParams) {
  logDataEvent('asset_card.asset_missing_info', {
    loggingId: 'cf5bfe8a-924a-468a-b90a-75d3d3d71f4d',
    componentType: ComponentType.card,
    action: ActionType.unknown,
    chainName,
    asset,
    uuid,
    message,
  });
}

export function logExploreAssetViewedEvents(params: AdhocParams, isMissingImage?: boolean) {
  logExploreAssetViewed(params);

  if (isMissingImage) {
    logExploreMissingAssetImage(params);
  }
}

export function logExploreCollectionClicked({
  context,
  chainName,
  collection,
  rank,
  toggleValue,
  rowIndex,
}: AdhocParams) {
  logEvent('explore.nft_collection_card.clicked', {
    loggingId: 'f5cc2ea6-6bda-4e9e-993f-ecf21eafc33b',
    componentType: ComponentType.card,
    action: ActionType.click,
    context,
    chainName,
    collection,
    rankIndex: rank,
    toggleValue,
    rowIndex,
  });
}

/**
 * this event does not get sent to amplitude as it is filtered out by the data team at the /amp endpoint. the event
 * is only sent snowflake into the `event.analytics_service` table.
 *
 * this event is currently over-firing.
 */
export function logExploreCollectionModuleViewed({ context, rowIndex }: AdhocParams) {
  logEvent(
    'explore.nft_collection_card.viewed',
    {
      loggingId: 'a7790715-0efa-4e6f-96f9-7b424f0a5b11',
      componentType: ComponentType.card,
      action: ActionType.view,
      context,
      rowIndex,
    },
    undefined,
    true,
  );
}

export function logExploreTabButtonClicked({ componentName }: AdhocParams) {
  logEvent('explore_tab_button', {
    loggingId: '981a177d-515e-4b99-8142-25fb485a06dd',
    componentType: ComponentType.button,
    action: ActionType.click,
    componentName,
  });
}

export function logExploreNftPromotionCardClicked({
  componentName,
  rank,
  collection,
  rowIndex,
}: AdhocParams) {
  logEvent('explore.nft_promotion_card.clicked', {
    componentType: ComponentType.card,
    action: ActionType.click,
    context: ExploreContext.NFT_PROMOTION,
    rankIndex: rank,
    collection,
    componentName,
    rowIndex,
  });
}

export function logExploreNftPromotionModuleViewed({ componentName, rowIndex }: AdhocParams) {
  logEvent('explore.nft_promotion_card.viewed', {
    componentType: ComponentType.card,
    action: ActionType.view,
    context: ExploreContext.NFT_PROMOTION,
    componentName,
    rowIndex,
  });
}

/* 
  SPOTLIGHT CARD EVENTS
*/
export function logSpotlightCardViewed({ componentName, rowIndex }: AdhocParams) {
  logEvent('explore.spotlight_card.viewed', {
    componentType: ComponentType.card,
    action: ActionType.view,
    context: ExploreContext.SPOTLIGHT,
    componentName,
    rowIndex,
  });
}

export function logSpotlightCTAButtonClicked({ componentName, rowIndex }: AdhocParams) {
  logEvent('explore.spotlight_cta_button.clicked', {
    componentType: ComponentType.button,
    action: ActionType.click,
    context: ExploreContext.SPOTLIGHT,
    componentName,
    rowIndex,
  });
}

export function logSpotlightDismissButtonClicked({ componentName, rowIndex }: AdhocParams) {
  logEvent('explore.spotlight_dismiss_button.clicked', {
    componentType: ComponentType.button,
    action: ActionType.click,
    context: ExploreContext.SPOTLIGHT,
    componentName,
    rowIndex,
  });
}

export function logSpotlightError({ componentName }: AdhocParams) {
  logEvent('explore.spotlight_section.error', {
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    context: ExploreContext.SPOTLIGHT,
    componentName,
  });
}

export function logSpotlightNoData({ componentName }: AdhocParams) {
  logEvent('explore.spotlight_section.no_data', {
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    context: ExploreContext.SPOTLIGHT,
    componentName,
  });
}

/* 
  CTA LIST SECTION EVENTS
*/
export function logCTAListItemViewed({ componentName, rowIndex }: AdhocParams) {
  logEvent('explore.cta_list_section.viewed', {
    componentType: ComponentType.card,
    action: ActionType.view,
    context: ExploreContext.CTA_LIST,
    componentName,
    rowIndex,
  });
}

export function logCTAListButtonClicked({ componentName, rowIndex }: AdhocParams) {
  logEvent('explore.cta_list_button.clicked', {
    componentType: ComponentType.button,
    action: ActionType.click,
    context: ExploreContext.CTA_LIST,
    componentName,
    rowIndex,
  });
}

export function logCTAListDismissClicked({ componentName, rowIndex }: AdhocParams) {
  logEvent('explore.cta_list_dismiss_button.clicked', {
    componentType: ComponentType.button,
    action: ActionType.click,
    context: ExploreContext.CTA_LIST,
    componentName,
    rowIndex,
  });
}

export function logCTAListError({ componentName }: AdhocParams) {
  logDataEvent('explore.cta_list_section.error', {
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    context: ExploreContext.CTA_LIST,
    componentName,
  });
}

export function logCTAListNoData({ componentName }: AdhocParams) {
  logDataEvent('explore.cta_list_section.no_data', {
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    context: ExploreContext.CTA_LIST,
    componentName,
  });
}

type Params = {
  chainId: string;
  rowIndex?: number;
};
export function logExploreGasTrackerLearnMoreClicked({ chainId, rowIndex }: Params) {
  logEvent('explore.gas_tracker_learn_more.clicked', {
    componentType: ComponentType.button,
    action: ActionType.click,
    chainId,
    rowIndex,
  });
}

export function useLogExploreGasTrackerViewed({ chainId, rowIndex }: Params) {
  return useLogEventOnMount('explore.gas_tracker.viewed', {
    componentType: ComponentType.card,
    action: ActionType.view,
    chainId,
    rowIndex,
  });
}

export function logExploreGasTrackerSendClicked({ chainId, rowIndex }: Params) {
  logEvent('explore.gas_tracker_send.clicked', {
    componentType: ComponentType.button,
    action: ActionType.click,
    chainId,
    rowIndex,
  });
}

export function logExploreGasTrackerSwapClicked({ chainId, rowIndex }: Params) {
  logEvent('explore.gas_tracker_swap.clicked', {
    componentType: ComponentType.button,
    action: ActionType.click,
    chainId,
    rowIndex,
  });
}

export function useLogNetworkFeeEducationViewed() {
  return useLogEventOnMount('explore.network_fee_education.viewed', {
    componentType: ComponentType.page,
    action: ActionType.view,
  });
}

export function logNetworkFeeEducationBridgeClicked() {
  logEvent('explore.network_fee_education_bridge.clicked', {
    componentType: ComponentType.button,
    action: ActionType.click,
  });
}

export function logNetworkFeeEducationGasTrackerClicked() {
  logEvent('explore.network_fee_education_gas_tracker.clicked', {
    componentType: ComponentType.button,
    action: ActionType.click,
  });
}

export function logExploreSectionNoData({ context }: AdhocParams) {
  logDataEvent('explore.explore_section.no_data', {
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    context,
  });
}

type ExploreMLFeedParams = {
  componentType: ComponentType;
  action: ActionType;
  triggerPointEventName: string;
  actionSource: string;
  order: number;

  surface: number;
  category: number;
  type: number;
  componentId: string;
  language: string;
  publishedAt: string;
  actionTitle: string;
  contentId: string;
  metadata: Record<string, any>;
  rowIndex: number;
};

export function logExploreFeedCard(params: ExploreMLFeedParams) {
  logEvent('uicomponent_event', {
    ...params,
  });
}

export function logExploreOnHomeError({ context }: AdhocParams) {
  logEvent('explore.explore_on_home.error', {
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    context,
  });
}

export function logExploreOnHomeAllSectionsNoData() {
  logEvent('explore.explore_on_home.all_sections_no_data', {
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
  });
}

export function logExploreOnHomeViewed() {
  logEvent('explore.explore_on_home.viewed', {
    componentType: ComponentType.page,
    action: ActionType.view,
  });
}
