import { logPriceChartPricesLoadFailed } from 'cb-wallet-data/stores/Prices/eventing';
import { CB_API_URL } from 'cb-wallet-env/env';
import { getJSON } from 'cb-wallet-http/fetchJSON';

const API_PATH_PRICES_V3 = `${CB_API_URL}/api/v3/coinbase.asset_stats_service.AssetStatsService/GetPriceChartForResolutions`;

export type CoinbaseRetailSupportedCurrency = {
  default_network: string;
  id: string;
  max_precision: string;
  message: string;
  min_size: string;
  name: string;
  status: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  supported_networks: any[];
};

export type CoinbaseRetailAccount = {
  id: string;
  name: string;
  active: boolean;
  type: 'wallet' | 'vault' | 'fiat';
  currency: {
    asset_id: string;
    slug: string;
    code: string;
  };
  balance: {
    amount: string;
    currency: string;
  };
  color: string;
};

type PercentChange = {
  hour: number;
  day: number;
  week: number;
  month: number;
  year: number;
  all: number;
};

export type LatestPrice = {
  readonly timestamp: string;
  readonly amount: {
    amount: string;
    currency: string;
    scale: number;
  };
  readonly percentChange: PercentChange;
};

export type Period = 'hour' | 'day' | 'week' | 'month' | 'year' | 'all';
export type PeriodV3 =
  | 'RESOLUTION_HOUR'
  | 'RESOLUTION_DAY'
  | 'RESOLUTION_WEEK'
  | 'RESOLUTION_MONTH'
  | 'RESOLUTION_YEAR'
  | 'RESOLUTION_ALL';

export type PricesForPeriod = {
  prices: [string, number][];
  yaxisScalingFactor: number;
};

export type AssetPrices = {
  latest: string;
  latestPrice: LatestPrice;
  hour: PricesForPeriod;
  day: PricesForPeriod;
  month: PricesForPeriod;
  week: PricesForPeriod;
  year: PricesForPeriod;
  all: PricesForPeriod;
};

export type AssetPriceResponse = {
  baseId: string; // asset id
  base: string; // asset symbol
  currency: string; // nativeCurrency
  prices: AssetPrices;
  unitPriceScale: number;
};

type ParsePricesProps = {
  priceCharts: PriceChartElement[];
  latestPrice: Price;
  currency: string;
  unitPriceScale: number;
};

export type AssetPriceV3Response = {
  priceCharts: PriceChartElement[];
  latestPrice: Price;
  unitPriceScale: number;
};

type Price = {
  price: string;
  timestamp: string;
};

type PriceChartElement = {
  currency: string;
  resolution: PeriodV3;
  priceChart: PriceChartPriceChart;
};
type PriceChartPriceChart = {
  uuid: string;
  symbol: string;
  yAxisScalingFactor: string;
  percentChange: string;
  prices: Price[];
};

export const RESOLUTION_DAY = 'RESOLUTION_DAY';
export const RESOLUTIONS = [
  'RESOLUTION_HOUR',
  RESOLUTION_DAY,
  'RESOLUTION_WEEK',
  'RESOLUTION_MONTH',
  'RESOLUTION_YEAR',
  'RESOLUTION_ALL',
];

export async function getAssetPricesV3(
  q: string,
  assetId: string | undefined,
  currency: string,
): Promise<AssetPriceResponse | undefined> {
  try {
    if (!assetId) {
      throw new Error(`invalid assetId provided: ${assetId}`);
    }

    const data = await getJSON<AssetPriceV3Response>(
      `${API_PATH_PRICES_V3}`,
      { q },
      { isThirdParty: true },
    );

    return parseAssetV3ResponseToV2Response(data);
  } catch (ex) {
    logPriceChartPricesLoadFailed(assetId ?? '', currency, ex);
  }

  return undefined;
}

function parseAssetV3ResponseToV2Response(data: AssetPriceV3Response): AssetPriceResponse {
  const {
    priceCharts: [priceChart],
    latestPrice,
    unitPriceScale,
  } = data;
  const {
    currency,
    priceChart: { uuid, symbol },
  } = priceChart;

  const base = symbol;
  const baseId = uuid;
  const prices = parsePriceV2Response({
    priceCharts: data.priceCharts,
    latestPrice,
    currency,
    unitPriceScale,
  });

  return {
    base,
    baseId,
    currency,
    prices,
    unitPriceScale,
  };
}

function parsePriceV2Response({
  priceCharts,
  latestPrice,
  currency,
  unitPriceScale,
}: ParsePricesProps): AssetPrices {
  const pricesMap = new Map<PeriodV3, Period>();
  const pricesChangeMap = new Map<PeriodV3, number>();

  RESOLUTIONS.forEach((resolution) => {
    pricesMap.set(
      resolution as PeriodV3,
      resolution.replace('RESOLUTION_', '').toLowerCase() as Period,
    );
  });

  const priceTimeline = priceCharts.reduce(function parseResponse(accumulator, priceChart) {
    const { resolution, priceChart: priceChartPrices } = priceChart;
    const { yAxisScalingFactor, percentChange, prices } = priceChartPrices;

    const pricesToTuple = prices.map((price) => {
      return [price.price, Number(price.timestamp)];
    });

    pricesChangeMap.set(resolution, Number(percentChange));

    const formattedPrice = {
      yAxisScalingFactor,
      percentChange,
      prices: pricesToTuple,
    };

    const key = pricesMap.get(resolution) as Period;

    // @ts-expect-error the types are a bit off here
    accumulator[key] = formattedPrice;

    return accumulator;
  }, {}) as Record<Period, PricesForPeriod>;

  const amountFormatted = {
    amount: latestPrice.price,
    currency,
    scale: unitPriceScale,
  };

  const convertMapToObject = [...pricesChangeMap.entries()].reduce(function mapPriceChangeToObj(
    accumulator: Partial<PercentChange>,
    [key, value]: [PeriodV3, number],
  ) {
    const newKey = pricesMap.get(key) as Period;
    accumulator[newKey] = value;
    return accumulator;
  },
  {}) as PercentChange;

  const latestPriceFormatted = {
    amount: amountFormatted,
    percentChange: convertMapToObject,
    timestamp: latestPrice.timestamp,
  };

  const latest = latestPrice.price;

  return {
    ...priceTimeline,
    latestPrice: latestPriceFormatted,
    latest,
  };
}
