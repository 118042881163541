import { defineMessages, useIntl } from 'react-intl';
import { useGetGameLeaderboard } from 'cb-wallet-data/hooks/Gamification/useGetGameLeaderboard';
import { useGetUserRank } from 'cb-wallet-data/hooks/Gamification/useGetUserRank';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { TextHeadline } from '@cbhq/cds-web/typography';

import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';

import { HomeSectionHeader } from '../HomeSectionHeader';

import { LeaderboardItem } from './LeaderboardItem';

const messages = defineMessages({
  player: {
    defaultMessage: 'PLAYERS',
    description: 'heading denoting the player',
  },
  score: {
    defaultMessage: 'TOTAL POINTS',
    description: 'heading denoting the score',
  },
  you: {
    defaultMessage: 'YOU',
    description: 'heading for your rank in OCS leaderboard',
  },
  leaderboardTitle: {
    defaultMessage: 'Top players',
    description: 'heading denoting the leaderboard',
  },
});

export function LeaderboardSection() {
  const { formatMessage } = useIntl();
  const { leaderboard } = useGetGameLeaderboard(1, 10);
  const { account } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS,
  });
  const { userRank } = useGetUserRank(account?.primaryAddress);
  if (!userRank) {
    return (
      <VStack gap={1}>
        <HomeSectionHeader title={formatMessage(messages.leaderboardTitle)} spacingVertical={2} />
        <HStack justifyContent="space-between" spacingVertical={2}>
          <TextHeadline as="label">{formatMessage(messages.player)}</TextHeadline>
          <TextHeadline as="label">{formatMessage(messages.score)}</TextHeadline>
        </HStack>
        {leaderboard?.map((user, index) => (
          <LeaderboardItem key={user.userAddress} rank={index + 1} user={user} />
        ))}
      </VStack>
    );
  }
  return (
    <VStack gap={1}>
      <HomeSectionHeader title={formatMessage(messages.leaderboardTitle)} spacingVertical={2} />
      <HStack justifyContent="space-between" spacingVertical={2}>
        <TextHeadline as="label">{formatMessage(messages.you)}</TextHeadline>
        <TextHeadline as="label">{formatMessage(messages.score)}</TextHeadline>
      </HStack>
      <LeaderboardItem rank={Number(userRank.rank)} user={userRank} isHighlighted />
      <TextHeadline as="label" spacingVertical={2}>
        {formatMessage(messages.player)}
      </TextHeadline>
      {leaderboard?.map((user, index) => (
        <LeaderboardItem key={user.userAddress} rank={index + 1} user={user} />
      ))}
    </VStack>
  );
}
