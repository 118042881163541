import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { getJSON } from 'cb-wallet-http/fetchJSON';

import { SwapAsset } from '../models/SwapAsset';
import { SwapAssetResponse } from '../types/SwapAssetResponse';

export const SWAP_ASSET_QUERY_KEY = 'swap/asset';

type Props = {
  contractAddress?: string;
  chainId?: string;
};

export type SwapAssetResponseType = SwapAssetResponse | undefined;

export async function fetchSwapAsset({
  contractAddress,
  chainId,
}: Props): Promise<SwapAssetResponseType> {
  try {
    const { result } = await getJSON<{ result: SwapAssetResponseType }>(
      SWAP_ASSET_QUERY_KEY,
      {
        contractAddress: contractAddress ?? '',
        chainId: chainId ?? '',
      },
      { apiVersion: 1 },
    );
    return result;
  } catch (error) {
    cbReportError({
      context: 'swap-error',
      error: new Error(`Failed to fetch swap asset`),
      severity: 'error',
      isHandled: false,
    });
    return Promise.resolve(undefined);
  }
}

export function useSwapAsset({ contractAddress, chainId }: Props): SwapAsset | undefined {
  // In some cases, like deeplinking, the contract can be the string `undefined`, we want to actually use undefined as a value instead
  const normalizedContractAddress = contractAddress === 'undefined' ? undefined : contractAddress;
  const swapAssetQuery = useQuery(
    getSwapAssetQueryKey({ chainId, contractAddress: normalizedContractAddress }),
    async () => fetchSwapAsset({ contractAddress: normalizedContractAddress, chainId }),
    {
      suspense: true,
      staleTime: 1000 * 30,
      notifyOnChangeProps: ['data'],
      enabled: Boolean(chainId),
    },
  );

  return useMemo(() => {
    if (!swapAssetQuery.data) {
      return undefined;
    }
    return new SwapAsset(swapAssetQuery.data);
  }, [swapAssetQuery]);
}

export function useGetSwapAsset() {
  const queryClient = useQueryClient();

  return useCallback(
    async function getSwapAsset({ contractAddress, chainId }: Props) {
      const normalizedContractAddress =
        contractAddress === 'undefined' ? undefined : contractAddress;

      const result = await queryClient.fetchQuery(
        getSwapAssetQueryKey({ chainId, contractAddress: normalizedContractAddress }),
        async () => fetchSwapAsset({ contractAddress: normalizedContractAddress, chainId }),
      );

      if (!result) return undefined;
      return new SwapAsset(result);
    },
    [queryClient],
  );
}

function getSwapAssetQueryKey({ chainId, contractAddress }: Props) {
  return [SWAP_ASSET_QUERY_KEY, chainId, contractAddress];
}
