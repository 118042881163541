import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export function useFormatPercent() {
  const { formatNumber } = useIntl();

  return useCallback(
    function formatPercent(value: number, scale: number) {
      return `${formatNumber(value, {
        style: 'percent',
        maximumFractionDigits: scale,
        minimumFractionDigits: scale,
      })}`;
    },
    [formatNumber],
  );
}
