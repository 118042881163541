import { Asset } from 'cb-wallet-data/stores/Assets/Asset';

import { ExploreAssetResponse } from '../types/ExploreAssetResponse';

export class ExploreAsset extends Asset {
  // TODO: move uuid to Asset when every instance of Asset expects uuids from Coinbsae WAC
  uuid: string;
  numOfBuys?: number;
  numOfSells?: number;

  constructor(exploreAssetResponse: ExploreAssetResponse) {
    super(
      exploreAssetResponse.name,
      exploreAssetResponse.currencyCode,
      exploreAssetResponse.address,
      exploreAssetResponse.decimals,
      exploreAssetResponse.imageURL,
      exploreAssetResponse.chainId,
      exploreAssetResponse.blockchain,
    );

    this.uuid = exploreAssetResponse.uuid;
    this.numOfBuys = exploreAssetResponse.numOfBuys;
    this.numOfSells = exploreAssetResponse.numOfSells;
  }

  equals(other: Asset): boolean {
    if (!(other instanceof ExploreAsset)) {
      return false;
    }
    return super.equals(other) && this.uuid === other.uuid;
  }
}
