import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { usePageOutageContext } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';
import { HStack } from '@cbhq/cds-web/layout';

import { useExperienceModalContext } from ':dapp/providers/ExperienceModalProvider';
import { useShareModalContext } from ':dapp/providers/ShareModalProvider';

import { useFirstInCompletedChallenge } from '../hooks/useFirstInCompletedChallenge';
import { useGetContentById } from '../hooks/useGetContentById';
import { useHomeFeatured } from '../hooks/useHomeFeatured';
import { useHomeOnchainExperiences } from '../hooks/useHomeOnchainExperiences';
import { OCSChallengeCard } from '../types/homeTypes';

import { HomeHeaderLoading } from './HomeHeaderLoading';
import { HomeHeroBanner } from './HomeHeroBanner';

const messages = defineMessages({
  heroBannerBadge: {
    defaultMessage: 'FRESH DROP',
    description: 'Badge for the hero banner in Home',
  },
});

export const testID = 'home-header';

export function HomeHeader() {
  const { formatMessage } = useIntl();
  const { setExploreApiIssue, exploreApiIssue } = usePageOutageContext();
  const { data, isLoading } = useHomeFeatured(setExploreApiIssue);
  const { data: experiences, isLoading: isExperiencesLoading } = useHomeOnchainExperiences(
    undefined,
    setExploreApiIssue,
  );
  const { openExperienceModal } = useExperienceModalContext();
  const searchParams = new URLSearchParams(window.location.search);
  const challengeIdParam = searchParams.get('challenge_id');
  const { openShareModal } = useShareModalContext();

  const { data: challengeById, isLoading: isLoadingChallengeById } = useGetContentById(
    challengeIdParam || '',
  );

  const handleClick = useCallback(
    (content: OCSChallengeCard) => () => {
      openExperienceModal(content);
    },
    [openExperienceModal],
  );

  const handleShareClick = useCallback(
    (content: OCSChallengeCard) => () => {
      openShareModal(content);
    },
    [openShareModal],
  );

  const firstInCompletedChallenge: OCSChallengeCard | null = useFirstInCompletedChallenge(
    data,
    experiences,
  );

  const content: OCSChallengeCard | null = useMemo(() => {
    // this is an override to show  shared experience card from the url
    if (challengeById?.content.ocsChallengeCard) {
      return { ...challengeById.content.ocsChallengeCard, id: challengeById.content.id };
    }

    return firstInCompletedChallenge;
  }, [
    challengeById?.content.id,
    challengeById?.content.ocsChallengeCard,
    firstInCompletedChallenge,
  ]);

  const creator = useMemo(() => {
    return content
      ? {
          name: content.creatorName,
          iconUrl: content.creatorImageUrl,
        }
      : undefined;
  }, [content]);

  const image = useMemo(() => ({ src: content ? content.imageUrl : '/park-1.png' }), [content]);
  const action = useMemo(
    () =>
      content
        ? {
            url: content.targetUrl,
            text: content.ctaText,
            points: content.points,
          }
        : undefined,
    [content],
  );

  if (isLoading || isExperiencesLoading || (isLoadingChallengeById && challengeIdParam)) {
    return <HomeHeaderLoading />;
  }

  if (!content || exploreApiIssue) {
    return null;
  }
  return (
    <HStack width="100%" justifyContent="space-between" alignItems="center" testID={testID}>
      <HomeHeroBanner
        title={content.title}
        subTitle={content.shortDescription}
        image={image}
        creator={creator}
        action={action}
        hasCompleted={content.hasCompleted === 2}
        badge={formatMessage(messages.heroBannerBadge)}
        onClick={handleClick({ ...content, id: content.id })}
        onShareClick={handleShareClick({ ...content, id: content.id })}
      />
    </HStack>
  );
}
