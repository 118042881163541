import { useMemo } from 'react';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { ExploreAssetResponse } from 'cb-wallet-data/stores/Explore/types/ExploreAssetResponse';
import { getJSON } from 'cb-wallet-http/fetchJSON';

export const TRENDING_SWAPS_QUERY_KEY = 'explore/trendingSwapsByNetworkId';

export type SupportedTrendingSwapsNetwork = 'solana' | 'base';

async function fetchTrendingSwaps(
  networkName: SupportedTrendingSwapsNetwork,
): Promise<ExploreAssetResponse[]> {
  const { result } = await getJSON<{ result: { data: ExploreAssetResponse[] } }>(
    TRENDING_SWAPS_QUERY_KEY,
    { network: `networks/${networkName}-mainnet` },
  );

  return result.data;
}

export function useTrendingSwaps(networkName: SupportedTrendingSwapsNetwork) {
  const { data, isLoading, refetch } = useQuery([TRENDING_SWAPS_QUERY_KEY, networkName], async () =>
    fetchTrendingSwaps(networkName),
  );

  return useMemo(
    () => ({
      data,
      isLoading,
      refetch,
    }),
    [data, isLoading, refetch],
  );
}
