import { useCallback } from 'react';
import { useSetAssetsMap } from 'cb-wallet-data/stores/Assets/hooks/useAssetsMap';
import { GenericAsset } from 'cb-wallet-data/stores/Assets/state';
import { ExploreAsset } from 'cb-wallet-data/stores/Explore/models/ExploreAsset';

export function useSetExploreAssetsMap() {
  const setAssetsMap = useSetAssetsMap();
  return useCallback(
    function setExploreAssetsMap(exploreAssets: ExploreAsset[]) {
      const assetsMap = new Map<string, GenericAsset>();
      for (const asset of exploreAssets) {
        assetsMap.set(asset.id, asset);
      }
      setAssetsMap(
        (oldAssetsMap: Map<string, GenericAsset>) => new Map([...oldAssetsMap, ...assetsMap]),
      );
    },
    [setAssetsMap],
  );
}
