import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { CSSProperties } from '@linaria/core';
import Image from 'next/image';
import { Button } from 'wallet-cds-web/components/Button';
import { ResponsiveProps, useSpectrum } from '@cbhq/cds-common';
import { IconButton } from '@cbhq/cds-web/buttons';
import { ContentCard, ContentCardBody } from '@cbhq/cds-web/cards/ContentCard';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, HStack, Spacer, VStack } from '@cbhq/cds-web/layout';
import { RemoteImage } from '@cbhq/cds-web/media/RemoteImage';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { TextBody, TextCaption, TextDisplay2, TextHeadline } from '@cbhq/cds-web/typography';
import { GradientBadge } from './GradientBadge';
export const testID = 'home-hero-banner';
type HomeHeroBannerProps = {
  title: string;
  subTitle?: string;
  image: {
    src: string;
    width?: number;
    height?: number;
  };
  badge?: string;
  creator?: {
    name: string;
    iconUrl: string;
  };
  action?: {
    points: number;
    text: string;
    url: string;
  };
  hasCompleted?: boolean;
  onClick?: () => void;
  onShareClick?: () => void;
};
const responsiveConfigForLayout: ResponsiveProps = {
  desktop: {
    flexDirection: 'row',
    alignItems: 'stretch'
  },
  tablet: {
    flexDirection: 'row',
    alignItems: 'stretch'
  },
  phone: {
    flexDirection: 'column-reverse',
    gap: 1
  }
};
const responsiveConfigForLeftSideWrapperLayout: ResponsiveProps = {
  desktop: {
    spacing: 4
  },
  tablet: {
    spacing: 3
  },
  phone: {
    spacing: 2,
    gap: 2
  }
};
const imageWrapperResponsiveConfig: ResponsiveProps = {
  phone: {
    spacing: 2
  }
};
const messages = defineMessages({
  alt: {
    defaultMessage: 'Image for {name} Home',
    description: 'Home creator image alt text'
  },
  ends: {
    defaultMessage: 'ENDS',
    description: 'Home ends alt text'
  },
  timer: {
    defaultMessage: '{timer}',
    description: 'Home timer alt text'
  },
  points: {
    defaultMessage: 'points',
    description: 'indication after a number to show that the number represents points for on chain summer'
  },
  earnsPoints: {
    defaultMessage: 'EARNS {points} PTS',
    description: 'indication of number of points to be gained from completing the featured challenge'
  },
  ctaPoints: {
    defaultMessage: ` +{points} points`,
    description: 'indication of number of points to be gained from completing the featured challenge'
  },
  challengeCompleted: {
    defaultMessage: 'Completed',
    description: 'indicator that the user has already completed this challenge'
  }
});
export function HomeHeroBanner({
  title,
  subTitle,
  image,
  badge,
  action,
  creator,
  hasCompleted,
  onClick,
  onShareClick
}: HomeHeroBannerProps) {
  const {
    formatMessage
  } = useIntl();
  const spectrum = useSpectrum();
  const {
    isPhone,
    isDesktop
  } = useBreakpoints();
  const buttonText = `${action?.text}${action?.points && action.points > 0 ? ` ${formatMessage(messages.ctaPoints, {
    points: action?.points
  })}` : ''}`;
  const imageClass = useMemo(() => {
    if (isPhone) {
      return "i1mi25an";
    }
    return "i12lvbb4";
  }, [isPhone]);
  const cardStyle = useMemo(() => {
    const style: CSSProperties = {
      padding: 0
    };
    return style;
  }, []);
  return <ContentCard testID={testID} borderRadius="roundedXLarge" overflow="hidden" style={cardStyle} gap={!isPhone ? 2 : 0} maxWidth="unset" width="100%" background="backgroundAlternate">
      <ContentCardBody gap={10} width="100%" body={<Box testID={`${testID}--layout`} responsiveConfig={responsiveConfigForLayout}>
            <VStack width="100%" justifyContent="space-between" alignItems="stretch" responsiveConfig={responsiveConfigForLeftSideWrapperLayout}>
              <VStack width="100%" height="100%" gap={2}>
                {badge && <GradientBadge title={badge} />}
                <Spacer />
                {creator && <HStack gap={1} alignItems="center">
                    <Box alignItems="center" borderRadius="rounded" overflow="hidden">
                      <RemoteImage alt={formatMessage(messages.alt, {
                name: creator.name
              })} source={creator.iconUrl} resizeMode="cover" height={40} width={40} />
                    </Box>
                    <TextCaption as="p" testID={`${testID}--creator-title`}>
                      {creator.name}
                    </TextCaption>
                  </HStack>}

                <Box maxWidth="400px">
                  <TextDisplay2 as="p" testID={`${testID}--title`}>
                    {title}
                  </TextDisplay2>
                </Box>
                {subTitle && <TextBody as="p" spacingBottom={5} color="foregroundMuted">
                    {subTitle}
                  </TextBody>}
              </VStack>
              {action ? <ThemeProvider spectrum={spectrum}>
                  <Button testID="home-hero-banner-button" onPress={onClick} block>
                    {hasCompleted ? <TextHeadline as="span" color="primaryForeground">
                        {formatMessage(messages.challengeCompleted)}
                      </TextHeadline> : <>
                        <TextHeadline as="span" color="primaryForeground">
                          {buttonText}
                        </TextHeadline>
                      </>}
                  </Button>
                </ThemeProvider> : null}
            </VStack>
            <Box position="relative" justifyContent="flex-end" width="100%" minWidth={isDesktop ? 550 : undefined} responsiveConfig={imageWrapperResponsiveConfig}>
              <Image alt={title} src={image.src} className={imageClass} fill={!isPhone} width={isPhone ? 500 : undefined} height={isPhone ? 212 : undefined} />
              <Box position="absolute" top={12} right={16} zIndex={1}>
                <ThemeProvider scale="small">
                  <IconButton compact name="share" testID="home-card-share" onClick={onShareClick} />
                </ThemeProvider>
              </Box>
            </Box>
          </Box>} />
    </ContentCard>;
}

require("./HomeHeroBanner.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./HomeHeroBanner.tsx");